import React, { useState } from "react";
import DatePicker from "react-datepicker";
import Select from "react-select";
import "react-datepicker/dist/react-datepicker.css";

const recurrenceOptions = [
  { value: null, label: "Does not repeat" },
  { value: "weekly", label: "Weekly" },
  { value: "bi-weekly", label: "Bi-Weekly" },
  { value: "monthly", label: "Monthly" }
];

const CalendarSelector = ({
  handleEventStart,
  handleEventEnd,
  handleEventRecurrence,
  eventStartDateTime,
  eventEndTime
}) => {
  const [startDate, setStartDate] = useState(eventStartDateTime);
  const [startTime, setStartTime] = useState(eventStartDateTime);
  const [endTime, setEndTime] = useState(eventEndTime);
  const [recurrence, setRecurrence] = useState(recurrenceOptions[0]);

  const handleRecurrenceChange = (selectedOption) => {
    setRecurrence(selectedOption);
    handleEventRecurrence(selectedOption.value);
  };

  return (
    <div
      style={{
        padding: "20px",
        maxWidth: "400px",
        margin: "0 auto",
        fontFamily: "Arial, sans-serif"
      }}
    >
      <div style={{ marginBottom: "15px" }}>
        <label
          htmlFor="date-picker"
          style={{ display: "block", marginBottom: "5px" }}
        >
          Select Date
        </label>
        <DatePicker
          id="date-picker"
          selected={startDate}
          onChange={(date) => {
            handleEventStart(date, startTime);
            setStartDate(date);
          }}
          dateFormat="P"
        />
      </div>

      <div style={{ display: "flex", flexDirection: "row" }}>
        <div style={{ flex: 1, marginBottom: "15px" }}>
          <label
            htmlFor="start-time-picker"
            style={{ display: "block", marginBottom: "5px" }}
          >
            Start Time
          </label>
          <DatePicker
            id="start-time-picker"
            selected={startTime}
            onChange={(time) => {
              handleEventStart(startDate, time);
              setStartTime(time);
            }}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={15}
            timeCaption="Start Time"
            dateFormat="h:mm aa"
          />
        </div>

        <div style={{ flex: 1, marginBottom: "15px" }}>
          <label
            htmlFor="end-time-picker"
            style={{ display: "block", marginBottom: "5px" }}
          >
            End Time
          </label>
          <DatePicker
            id="end-time-picker"
            selected={endTime}
            onChange={(time) => {
              setEndTime(time);
              handleEventEnd(time);
            }}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={15}
            timeCaption="End Time"
            dateFormat="h:mm aa"
          />
        </div>
      </div>

      <div style={{ marginBottom: "15px" }}>
        <label
          htmlFor="recurrence-select"
          style={{ display: "block", marginBottom: "5px" }}
        >
          Repeat
        </label>
        <Select
          id="recurrence-select"
          options={recurrenceOptions}
          value={recurrence}
          onChange={handleRecurrenceChange}
          styles={{
            menu: (provided) => ({
              ...provided,
              zIndex: 9999 // Ensure dropdown is not covered
            })
          }}
        />
      </div>
    </div>
  );
};

export default CalendarSelector;
