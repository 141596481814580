import { ORG_NAME, ORG_ID } from "../../util/CommonConstants";
import { TOKEN_KEY } from "../../util/Auth";
import "../../static/css/AdminScreen.css";

export const AdminScreen = () => {
    const currentUser = JSON.parse(localStorage.getItem(TOKEN_KEY));
    const organization = localStorage.getItem(ORG_NAME);

    return (
        <div>
            <h1>{organization} Admin</h1>

            <div className="admin-container">
                <img src={currentUser.profile_picture} alt="Profile Picture" />
                <h2>{currentUser.first_name} {currentUser.last_name}</h2>
                <i className="fa fa-bell"></i>
            </div>

            <div className="approval-container">
                <h3>Approvals</h3>
                <div className="approval-card">
                    <h2>Cleanups</h2>
                    <h3>0</h3>
                    <p>Awaiting Approval</p>
                </div>

                <div className="approval-card">
                    <h2>Events</h2>
                    <h3>0</h3>
                    <p>Awaiting Approval</p>
                </div>

                <div className="approval-card">
                    <h2>Adopt-a-sites</h2>
                    <h3>0</h3>
                    <p>Awaiting Approval</p>
                </div>

                <div className="approval-card">
                    <h2>Cleanup Groups</h2>
                    <h3>0</h3>
                    <p>Awaiting Approval</p>
                </div>

                <div className="button-container">
                    <button>Generate Cleanup Report</button>
                    <button>Message All Users</button>
                </div>
            </div>
        </div>
    );
};